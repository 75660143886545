export default function header(){
    let submenuMobile = document.querySelectorAll('.js-open-submenu-mobile');
    let current;
    
    submenuMobile.forEach((item) => {
        item.addEventListener('click', () => {
            let parent = item.parentNode;
    
            if(current && current != parent){
                current.classList.remove('is-active');
            }
            
            parent.classList.toggle('is-active');
            current = parent;
        });
    })
}