import { Swiper, Navigation, Pagination, Autoplay } from "swiper/dist/js/swiper.esm.js";

Swiper.use([Navigation, Pagination, Autoplay]);

export default () => {
  var swiper = new Swiper('.gallery-modal-slider', {
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
  });

  var mainBanner = new Swiper('.main-banner__slide', {
      watchOverflow: true,
      loop:true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      navigation: {
        nextEl: '.main-banner-next',
        prevEl: '.main-banner-prev',
      },
      pagination: {
        el: '.main-banner-pagination',
        clickable: true,
      },
  });

  var announcementsSlide = new Swiper('.announcements__slide', {
      watchOverflow: true,
      spaceBetween: 20,
      slidesPerView: 3,
      loop:true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      breakpoints: {
        640: {
          slidesPerView: 1,
        }
      },
      navigation: {
        nextEl: '.announcements-navigation-next',
        prevEl: '.announcements-navigation-prev',
      },
  });

  var foodOffersSlide = new Swiper('.offers__slide', {
    watchOverflow: true,
    spaceBetween: 20,
    slidesPerView: 6,
    breakpoints: {
      640: {
        slidesPerView: 1,
      },
      1000: {
        slidesPerView: 3,
      },
      1200: {
        slidesPerView: 4,
      }
    },
    pagination: {
      el: '.offers__pagination',
      clickable: true,
    },
  });

  var associatesSlide = new Swiper('.associates-slide', {
    slidesPerView: 4,
    slidesPerColumn: 4,
    spaceBetween: 30,
    pagination: {
      el: '.associates__pagination',
      clickable: true,
    },
    breakpoints: {
      640: {
        slidesPerView: 2,
        slidesPerColumn: 3,
      },
      1000: {
        slidesPerView: 3,
        slidesPerColumn: 3,
      },
    },
  });
};
