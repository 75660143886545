export default function uploadName(){
    if(document.querySelector('#curriculo') && document.querySelector('.contact__upload-name')){
        let uploadButton = document.querySelector('#curriculo');
        let uploadName = document.querySelector('.contact__upload-name p');
        let removeFile = document.querySelector('.contact__upload-name i');
    
        uploadButton.addEventListener('change', () => {
            let fileName = uploadButton.files[0].name;
            uploadName.textContent = fileName;
        });

        removeFile.addEventListener('click', () => {
            uploadButton.value = '';
            uploadName.textContent = 'Nenhum arquivo enviado';
        });
    }
}