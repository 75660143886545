export default function videoModal(){
    if(document.querySelectorAll('.js-video') && document.querySelector('.video-modal')){
        let videoPlay = document.querySelectorAll('.js-video');
        let videoModal = document.querySelector('.video-modal');
        let modalPlayer = document.querySelector('.video-modal__container iframe');
        let modalPlayerVideo = document.querySelector('.video-modal__container video');
        
        videoPlay.forEach((el) => {
            let itemContent = el.querySelector('.video-player');
            let videoClose = el.querySelector('.video-close');

            el.addEventListener('click', () => {
                videoModal.classList.add('is-active');
                document.querySelector('.video-modal__container').appendChild(itemContent);
                document.querySelector('.video-modal__container').appendChild(videoClose);
                document.body.style.overflow = 'hidden';
            });

            videoClose.addEventListener('click', () => {
                if(modalPlayer){
                    let iframeSrc = modalPlayer.src;
    
                    modalPlayer.src = iframeSrc;
                }else if(modalPlayerVideo){
                    let videoSrc = modalPlayerVideo.src;
    
                    modalPlayerVideo.src = videoSrc;
                }
                videoModal.classList.remove('is-active');
                document.querySelector('.video-modal__container').removeChild(itemContent);
                document.querySelector('.video-modal__container').removeChild(videoClose);
                document.body.style.overflow = 'auto';
            });
        });
    }
}