export default function luckSearch() {
    if (document.querySelector('.luck-numbers__container') && document.querySelector("#luck-form input[type='submit']")){
        let luckContainer = document.querySelector('.luck-numbers__container');
        let luckSearchButton = document.querySelector("#luck-form input[type='submit']");

        if (!luckContainer.classList.contains('is-active')) {
            luckSearchButton.addEventListener('click', () => {
                luckContainer.classList.add('is-active');
            });
        }
    }
}
