export default function associatesModal(){
    if(document.querySelectorAll('.associates__item')){
        let associatedItem = document.querySelectorAll('.associates__item');
        let associatesModal = document.querySelector('.associates-modal');

        
        associatedItem.forEach((el) => {
            let modalClose = el.querySelector('.associates-modal__close');
            let itemContent = el.querySelector('.associates__content');

            el.querySelector('.associates__img').addEventListener('click', () => {
                associatesModal.classList.add('is-active');
                document.querySelector('.associates-modal__content').appendChild(itemContent);
                document.body.style.overflow = 'hidden';
            });
            
            modalClose.addEventListener('click', () => {
                associatesModal.classList.remove('is-active');
                document.querySelector('.associates-modal__content').removeChild(itemContent);
                document.body.style.overflow = 'auto';
            });
        });
    }
}
